<!-- <spinner *ngIf="spinner" [center]="true" size="la-2x" color="grey" type="ball-triangle-path"></spinner> -->
<div style="width: 100%;">
  <mat-form-field [floatLabel]="float" appearance="{{appearance}}" style="width: 100%; margin-bottom: -1.25em;">
    <mat-label *ngIf="float != 'never'">Datatype</mat-label>
    <mat-select [(value)]="selDatatype">
        <mat-option *ngFor="let datatype of dataTypes;let i = index" [value]="datatype.__id" 
        (click)="selectedDatatype(selDatatype)"> <div> {{datatype.name}}</div> </mat-option>
    </mat-select>
    </mat-form-field> 
</div>

