import { Component, Inject, Input, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PageService } from '../../services/page-service.service';
import { UntypedFormControl } from '@angular/forms';
import { AdminService } from 'src/app/modules/admin/admin.service';
import { MetaService } from '../../services/meta-service';
import { WidgetService } from '../../services/widget-service.service';

@Component({
  selector: 'app-panel-configuration',
  templateUrl: './panel-configuration.component.html',
  styleUrls: ['./panel-configuration.component.css']
})
export class PanelConfigurationComponent implements OnInit {

  panelMeta: any;
  disableSpaceBetween: boolean = false;
  opacity: any;
  spinner: boolean = false;
  ColorControl: UntypedFormControl = new UntypedFormControl('');
  background: any = {
                      color: '#fff',
                      image: {
                        url: '',
                        opacity: 50
                      }
                    };
  margin: any = {
    left: 0,
    right: 0
  }
  alignments: string[] = [
    'left',
    'right',
    'center',
    'spaced out',
  ]
  layoutMap: any = { list: [] };

  pageReferenceMap: any = {
    list: []
  }
  panelImgBgConfigOpen: boolean = false
  layoutImgBgConfigOpenFor: number = -1
  lastPlacedCursorPosition: number = -1

  draggingEndForMargin: string = ''

  constructor(
    public dialogRef: MatDialogRef<PanelConfigurationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public pageService: PageService,
    private adminService: AdminService,
    private metaService: MetaService,
    private widgetService: WidgetService
  ) { }

  ngOnInit(): void {
    // this.ColorControl.valueChanges.pipe(debounceTime(3000), distinctUntilChanged()).subscribe(() => {
    //   // this.updatePageMeta()
    //   //send updated page meta to subject, so as to make it available all across
    //   // this.metaService.pageMeta.next(this.pageMeta)
    //   // this.savePageMeta(this.pageMeta);
    // })
    console.log(this.margin, this.background.image.url)
    this.panelMeta = JSON.parse(JSON.stringify(this.data))
    // this.layoutMap = this.panelMeta.layoutMap
    console.log("panel settings component", this.panelMeta)
    if(this.panelMeta?.background){
      this.background = this.panelMeta.background
    }
    if(this.panelMeta?.margin){
      console.log('inside')
      this.margin = this.panelMeta?.margin
    }

    if(!this.panelMeta.layoutCount) this.panelMeta.layoutCount = "1";
    this.opacity = this.background.image.opacity;

    this.createPageRefMap()
  }

  alignmentChanged(alignment){
    console.log('alignment changed', alignment)
    this.panelMeta['alignment'] = alignment
    this.pageService.$panelMetaChange.next(this.panelMeta)
  }

  onLayoutChange(event){
    console.log('layout this.panelMeta', this.panelMeta)
    let previousCount = JSON.parse(JSON.stringify(this.panelMeta.layoutCount));
    previousCount = Number(previousCount);
    console.log('layout previousCount', previousCount)
    console.log('layout changed', event);
    this.panelMeta.layoutCount = event.value;
    let columnCount = Number(this.panelMeta.layoutCount);

    let isAddColumn = true;
    let differenceCount = 0;
    if(columnCount > previousCount){
      differenceCount = columnCount - previousCount;
      console.log('differenceCount', differenceCount);
    } else if (columnCount < previousCount) {
      differenceCount = previousCount - columnCount;
      isAddColumn = false;
    }

    for (let index = 0; index < differenceCount; index++) {
      if(isAddColumn){
        let layoutId = new Date().setSeconds(new Date().getSeconds() + index).valueOf();
        this.panelMeta.layoutMap.list.push(layoutId);
        this.panelMeta.layoutMap[layoutId] = {
          gridX: 0,
          list: []
        }
      } else if (!isAddColumn){
        let lastLayoutId = this.panelMeta.layoutMap['list'][this.panelMeta.layoutMap['list'].length - 1];
        let lastLayoutMap = this.panelMeta.layoutMap[lastLayoutId];

        let moveableLayoutId = this.panelMeta.layoutMap['list'][this.panelMeta.layoutMap['list'].length - 2];
        let moveableLayoutMap = this.panelMeta.layoutMap[moveableLayoutId];
        moveableLayoutMap.list = moveableLayoutMap.list.concat(lastLayoutMap.list);
        lastLayoutMap?.list?.forEach(row => {
          moveableLayoutMap[row] = lastLayoutMap[row];
        });

        this.panelMeta.layoutMap.list.pop();
        delete this.panelMeta.layoutMap[lastLayoutId];
      }
    }

    let avgGridX = 12 / columnCount;
    this.panelMeta.layoutMap.list.forEach(layout => {
      this.panelMeta.layoutMap[layout].gridX = avgGridX;
    });

    // if(this.panelMeta.layoutMap)
    console.log('layout map', this.panelMeta.layoutMap)
    this.pageService.$panelMetaChange.next(this.panelMeta)
  }

  saveConfig(){
    console.log(this.margin)
    this.background.image.opacity = this.opacity;
    this.panelMeta.background = this.background;
    this.panelMeta.margin = this.margin
    console.log(this.panelMeta)
    this.pageService.$panelMetaChange.next(this.panelMeta)
    this.dialogRef.close(1)
  }

  colorChanged(color, layoutIndex?: number){
    console.log(color, "layout: ", layoutIndex)
    if (layoutIndex == 0 || layoutIndex) {
      let layoutId = this.panelMeta['layoutMap']['list'][layoutIndex]
      if (this.panelMeta.layoutMap[layoutId]?.['background']?.hasOwnProperty('color')){
        this.panelMeta.layoutMap[layoutId]['background']['color'] = color
      } else {
        this.panelMeta.layoutMap[layoutId]['background'] = { image: { url: "", opacity: 1 }, color: color }
      }
    } else {
      this.background.color = color;
      console.log(this.background)
      this.panelMeta.background = this.background;
    }
    this.pageService.addToColorPalette(color)
    console.log(this.panelMeta)
  }
  createPanel(event){
    console.log(event)
  }
  newWidget(i){
    console.log(i)
  }

  backgroundImageChanged(e: any, layoutIndex?: number){
    console.log(e, layoutIndex)
    if (layoutIndex == 0 || layoutIndex) {
      let layoutId = this.panelMeta['layoutMap']['list'][layoutIndex]
      if (this.panelMeta.layoutMap[layoutId]?.['background']?.['image']?.hasOwnProperty('url')){
        this.panelMeta.layoutMap[layoutId]['background']['image']['url'] = e.value
      } else {
        this.panelMeta.layoutMap[layoutId]['background'] = { image: { url: e.value, opacity: '.5' }, color: '' }
      }
    } else {
      this.background.image.url = e.value;
      this.panelMeta.background = this.background
    }
    console.log(this.panelMeta)
  }

  async imageSelectionChange(e, layoutIndex?: number){
    console.log(e, layoutIndex)
    if(e){
      this.spinner = true;
      await this.adminService.fileUpload(e).then((res: any) => {
        console.log(res)
        if (layoutIndex == 0 || layoutIndex) {
          let layoutId = this.panelMeta['layoutMap']['list'][layoutIndex]
          if (this.panelMeta.layoutMap[layoutId]?.['background']?.['image']?.hasOwnProperty('url')){
            this.panelMeta.layoutMap[layoutId]['background']['image']['url'] = res
          } else {
            this.panelMeta.layoutMap[layoutId]['background'] = { image: { url: res, opacity: '.5' }, color: '' }
          }
        } else {
          this.background.image.url = res
          this.background.image.opacity = '.5'
        }
        this.spinner = false;
      });
    }else{
      this.background.image.url = null
    }
    this.panelMeta.background = this.background
  }

  opacityChanged(event){
    this.opacity = event.value
    console.log(event, this.opacity)
  }

  setAppearance(layoutId: string, appearance: string): void {
    // console.log("set appearance", layoutId, appearance)
    // console.log("panel meta", JSON.parse(JSON.stringify(this.panelMeta)))
    // console.log("before", JSON.parse(JSON.stringify(this.panelMeta.layoutMap)))
    this.panelMeta.layoutMap[layoutId]['appearance'] = appearance;
    console.log("layout updated", JSON.parse(JSON.stringify(this.panelMeta.layoutMap)))
  }


  createPageRefMap(){
    let pageMeta = this.metaService.pageMeta.value
    pageMeta.panels?.forEach(panel => {
      if(panel.type !== 'listpanel' && panel.type !== 'searchpanel') {
        // this.allPanels.push({ id: panel.id, name: panel.name, type: panel.type, layoutMap: panel.layoutMap })

        let widgetOptions: any[] = []
        let widgetMetas = this.widgetService.getWidgetsFromPanel(panel)
        widgetMetas.forEach(wid => {
          widgetOptions.push({
            name: wid.name,
            __id: `${panel.id}.${wid.id}`,
            value: '${' + `${panel.id}.${wid.id}` + '}',
            dataType: "string"
          })
        })

        this.pageReferenceMap.list.push(panel.id)
        this.pageReferenceMap[panel.id] = {
          id: panel.id,
          displayName: panel.name,
          fields: widgetOptions,
          options: {}
        }
      }
    });
    console.log("page refs set", this.pageReferenceMap)
  }

  // toggleImgConfig(i){
  //   console.log("toggle hit", i, this.layoutImgBgConfigOpenFor)
  //   this.layoutImgBgConfigOpenFor = (this.layoutImgBgConfigOpenFor > -1 && this.layoutImgBgConfigOpenFor === i) ? -1 : i
  //   console.log("after", this.layoutImgBgConfigOpenFor)
  // }

  cursorPlaced(event){
    console.log("cursor placed", event)
    this.lastPlacedCursorPosition = event.target?.selectionStart
  }

  templateValueSelected(event, value, layoutIndex: number){
    value = value.substring(0, this.lastPlacedCursorPosition) + event.value + value.substring(this.lastPlacedCursorPosition)
    this.backgroundImageChanged({ value: value }, layoutIndex)
  }

  toggleBgImgConfig(layoutIndex){
    if (layoutIndex == 0 || layoutIndex) {
      // console.log("inside if")
      this.layoutImgBgConfigOpenFor = (this.layoutImgBgConfigOpenFor > -1 && this.layoutImgBgConfigOpenFor === layoutIndex) ? -1 : layoutIndex
    } else {
      // console.log("inside else")
      this.panelImgBgConfigOpen = this.panelImgBgConfigOpen ? false : true
    }
  }

  updateOpacity(event, i){
    console.log("update opacity", event)
    if (this.panelMeta['layoutMap'][this.panelMeta['layoutMap']['list'][i]]?.background) {
      this.panelMeta['layoutMap'][this.panelMeta['layoutMap']['list'][i]].background['opacity'] = event
    }
    console.log("opacity set", this.panelMeta['layoutMap'][this.panelMeta['layoutMap']['list'][i]].background['opacity'])
  }

  // templateValueSelected(event, layoutIndex){
  //   console.log("event", event)
  //   console.log("url", event.value)
  //   // this.background.image.url = event.value
  //   this.backgroundImageChanged(event, layoutIndex)
  // }

  marginChange(event, position: string){
    console.log("event", event)
    if (position == 'left') {
      this.margin.left = event
    } else if (position == 'right') {
      this.margin.right = event
    }
    console.log("margin", this.margin)
  }

  displayThumbText(num){
    // console.log("displayThumbText", num)
    let showVal: any = ''
    if(this.draggingEndForMargin == 'left'){
      showVal = num
    }else if (this.draggingEndForMargin == 'right'){
      showVal = 100 - num
    }
    // console.log("showVal")
    return showVal
  }

  dragStart(event, dir){
    console.log("drag started", event, "from", dir)
    this.draggingEndForMargin = dir
  }

}
