import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { SpinnerService } from 'src/app/shared/spinner/spinner.service';
import { WIDGET_OPTIONS } from '../services/widget-utility.service'

@Component({
  selector: 'app-widget-wrapper',
  templateUrl: './widget-wrapper.component.html',
  styleUrls: ['./widget-wrapper.component.scss']
})
export class WidgetWrapperComponent implements OnInit, OnChanges {

  @Input() widgetMeta: any
  @Input() panelId: any
  @Input() pageMeta: any
  @Input() builderMode: any
  @Input() selectedWidgetId: any
  @Input() widgetOptions: WIDGET_OPTIONS
  @Input() contextActions: any

  @Output() widgetClick = new EventEmitter<any>()
  @Output() widgetSelection = new EventEmitter<any>()
  @Output() widgetDeletion = new EventEmitter<any>()
  @Output() widgetHover = new EventEmitter<any>()
  @Output() newWidgetMeta = new EventEmitter<any>()
  @Output() userInputReceived = new EventEmitter<any>();
  @Output() applyImageHeight = new EventEmitter<any>();
  @Output() onExecuteAction = new EventEmitter<any>();
  @Output() contextMenuActions = new EventEmitter<any>();

  constructor(
    public sps: SpinnerService
  ) { }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    // console.log("changes", changes)
  }

  raiseWidgetHover(event){
    if(!this.builderMode) return
    this.widgetHover.emit(event)
  }
  raiseWidgetSelection(event){
    if(!this.builderMode) return
    this.widgetSelection.emit(event)
  }
  raiseWidgetDeletion(event){
    if(!this.builderMode) return
    this.widgetDeletion.emit(event)
  }
  raiseWidgetClick(event){
    if(!this.builderMode) return
    this.widgetClick.emit(event)
  }
  raiseNewWidgetMeta(data){
    if(!this.builderMode) return
    this.newWidgetMeta.emit(data)
  }
  raiseUserInputReceived(data){
    this.userInputReceived.emit(data)
  }
  raiseApplyImageHeight(data){
    this.applyImageHeight.emit(data)
  }

  raiseContextMenuActions(data){
    this.contextMenuActions.emit(data)
  }

  raiseOnExecuteAction(data){
    this.onExecuteAction.emit(data)
  }
}
