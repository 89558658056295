<div class="builder">

  <!-- builder header -->
  <div class="builder-toolbar">
    <!-- <form-toolbar></form-toolbar> -->
    <app-builder-toolbar></app-builder-toolbar>
  </div>

  <!-- builder body -->
  <div  fxLayout="row" class="builder-body main-content" >

    <div
      class="builder-canvas" [ngStyle]="formStyle?.style && !showResponse && !showAutomation ? formStyle.style : {}"
      [ngClass.gt-md]="{'builder-canvas-gt-md': true,'canvas-gt-md-full': isSidebarCollapsed}"
      [ngClass.md]="{'builder-canvas-md': true,'canvas-md-full': isSidebarCollapsed}"
      [ngClass.lt-md]="{'builder-canvas-lt-md': true,'canvas-lt-md-full': isSidebarCollapsed}"
    >
      <mat-tab-group #tabGroup animationDuration="1000ms"
         mat-align-tabs="center"
        (selectedTabChange)="tabChanged($event)">
          <mat-tab  >
              <ng-template mat-tab-label>
                  <mat-icon style="margin-right: 1rem;">edit_document</mat-icon>
                  Edit
              </ng-template>
              <div #buildercanvas >
                <!-- ngClass.gt-md="form-canvas-gt-md"
                    ngClass.md="form-canvas-md"
                    ngClass.lt-md="form-canvas-lt-md"
                    ngClass.xs="form-canvas-xs" -->
                <!-- builder canvas [fxLayoutAlign]="formStyle?.alignment && formStyle?.alignment != 'full'  ? formStyle.alignment + ' start' : 'center start'"-->
                <!-- [ngStyle]="formStyle?.style && !showResponse ? formStyle.style : {}" -->
                <div class="canvas-wrap container-with-thin-scrollbar"
                 >
                 <div
                    class="form-canvas"
                    [ngStyle]="getMergedStyles()"
                  >
                  <form-canvas *ngIf="ready"></form-canvas>
                </div>
              </div>
              </div>
          </mat-tab>

          <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon style="margin-right: 1rem;">library_books</mat-icon>
                Responses
            </ng-template>
            <div>
              <app-form-responses *ngIf="showResponse" [formCode]=""></app-form-responses>
            </div>
          </mat-tab>

          <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon style="margin-right: 1rem;">wifi_protected_setup</mat-icon>
                Automation
            </ng-template>
            <div>

              <app-form-automate *ngIf="showAutomation"> </app-form-automate>
            </div>
          </mat-tab>
      </mat-tab-group>
    </div>

    <div
      class="sidebar builder-menu"
      [ngClass.lt-md]="{'builder-menu-lt-md': true,'sidebar-lt-md-expanded': !isSidebarCollapsed}"
      [ngClass.md]="{'builder-menu-md': true,'sidebar-md-expanded': !isSidebarCollapsed}"
      [ngClass.gt-md]="{'builder-menu-gt-md': true,'sidebar-gt-md-expanded': !isSidebarCollapsed}"
      fxHide.xs
      [class.collapsed]="isSidebarCollapsed"
    >

      <button class="toggle-button" (click)="toggleSidebar()">
        <span *ngIf="isSidebarCollapsed" class="arrow">&lt;</span>
        <span *ngIf="!isSidebarCollapsed" class="arrow">&gt;</span>
      </button>

      <!-- builder menu -->
      <div [hidden]="isSidebarCollapsed" class="builder-menu" id="builder-menuid" >
      <mat-tab-group #tabGroupSidebar class="tabGroup" mat-stretch-tabs (selectedTabChange)="sideTabChanged($event)">
        <mat-tab mat-align-tabs="center">
          <ng-template mat-tab-label class="tools">Tools</ng-template>
          <div style="width: 100%;">
            <app-builder-menu-tools (createPanel)="createPanel($event)"
           [sectionsToHide]="sectionsToHide"></app-builder-menu-tools>
          </div>
        </mat-tab>
        <mat-tab class="pagesTab" label="Pages" mat-align-tabs="center">
          <ng-template mat-tab-label class="pages">Pages</ng-template>
          <div style="width: 100%; ">
            <app-form-settings></app-form-settings>
          </div>
        </mat-tab>
        <mat-tab class="pagesTab" label="Themes" mat-align-tabs="center">
          <ng-template mat-tab-label class="themes">Themes</ng-template>
          <div style="width: 100%;min-height: 100vh;">
            <app-form-themes #themesSettingsComponent></app-form-themes>
          </div>
        </mat-tab>
        <mat-tab class="settingsTab" [disabled]="settingsTabDisabled" label="Settings" mat-align-tabs="center">
          <ng-template mat-tab-label class="settings">Settings</ng-template>
          <ng-container *ngIf="!settingsTabDisabled">
            <app-widget-configuration></app-widget-configuration>
          </ng-container>

        </mat-tab>
      </mat-tab-group>
     </div>
    </div>

  </div>
</div>



<!-- builder header -->

<!-- builder body -->
