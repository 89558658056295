<spinner class="spinner" [selfSpin]="spinner" size="la-2x" color="grey" type="ball-fussion"></spinner>

<div >
  <div *ngIf="showSearch" fxLayout="row" fxLayoutAlign="center center" style="margin-top: -30px;">
    <div fxLayout="column" fxFlex="5"></div>
    <div fxLayout="column" fxFlex="70">
    <mat-form-field floatLabel="never" style="min-width: 20%;width: 100%;" >
      <mat-icon  matPrefix style="margin-bottom: 14.25px">search
      </mat-icon>
      <mat-label>
        <span>&nbsp;&nbsp;Search</span>
      </mat-label>
      <input matInput [(ngModel)]="connectionSearchField" fxFlexFill />
      <button class='icon-button-small' matSuffix mat-icon-button
        (click)="connectionSearchField=''"  >
        <mat-icon>clear</mat-icon>
      </button>
    </mat-form-field>
    </div>
    <div fxLayout="column" fxFlex="20" >
      <button matTooltip="Search Connections" mat-icon-button color="primary"  style="margin-top:-13px;" (click)="getAllConnections()">
      <mat-icon>search</mat-icon>
    </button>
    </div>
    <div fxLayout="column" fxFlex="5"></div>
  </div>
    <div fxLayout="row" fxLayoutAlign="center center" style="margin-top: -10px;">
        <div fxLayout="column" fxFlex="5"></div>
        <div fxLayout="column" fxFlex="90">
            <mat-list role="list">
            <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
                <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
                  <mat-list-item *ngIf="node.name != '__add_object'" cdkDropList (cdkDropListDropped)="dropWidget(node)">
                    <div fxLayout="row" style="width: 100%;" *ngIf="!node.isLoadingObject"
                      (mouseover)="mouseover($event, node)" (mouseout)="mouseout($event, node)" >
                        <div cdkDrag fxLayout="column" fxFlex="80">
                        <div fxLayout="row"  class="border">
                          <div fxLayout="column" fxFlex="10">
                            <mat-icon *ngIf="node.hovered" matTooltip="Drag to build visual on {{node.name}}" style="cursor: pointer;margin-left: -10px;margin-top: -3px;color: #565656;"
                            > drag_indicator </mat-icon>
                          </div>
                          <div fxFlex="90" matTooltip="Drag to build visual on {{node.name}}" style="cursor: pointer"
                           > {{node.name}}</div>
                        </div>

                        </div>
                        <div fxLayout="column" fxFlex="10">

                        </div>
                        <div fxLayout="column" fxFlex="10">

                        </div>
                      </div>
                  </mat-list-item>

                </mat-tree-node>
                <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding>
                   <mat-list-item>

                    <div fxLayout="row" (mouseover)="mouseover($event, node)" (mouseout)="mouseout($event, node)"
                     style="width: 100%;margin: top -20px !important;;margin: bottom -20px !important;" fxLayoutAlign="start center">
                      <div fxLayout="column" fxFlex="15">
                        <button mat-icon-button matTreeNodeToggle matTooltip="{{treeControl.isExpanded(node) ? '': 'Expand Connection to see Actions'}}"
                          [attr.aria-label]="'toggle ' + node.name" (click)="loadAndSetChildren(node)">
                          <mat-icon
                          color="primary" class="mat-icon-rtl-mirror" >
                            {{treeControl.isExpanded(node) ? 'indeterminate_check_box' : 'add_box'}}
                          </mat-icon>
                        </button>
                      </div>
                      <div fxLayout="column" fxFlex="10" *ngIf="node?.tree_type == 'connection'">
                        <img *ngIf="node?.nodeMap?.options?.['box_logo_url']"  matTooltip="{{node?.nodeMap?.options?.['box_name']}}"
                        mat-card-image src="{{ node?.nodeMap?.options?.['box_logo_url'] }}"
                          style="height: 20px; width: 20px; margin: 5px 0px 5px 0px" />
                      </div>
                      <div fxLayout="column" fxFlex="65" style="margin-left: 4px;" fxLayoutAlign="center start" matTooltip="{{node.name}}">
                        <div fxLayout="row" style="overflow: hidden;white-space: nowrap;width: 100%">{{node.name}}</div>
                      </div>
                      <div fxLayout="column" fxFlex="10" *ngIf="!node?.tree_type || node?.tree_type != 'connection'">
                      </div>

                      <div fxLayout="column" fxFlex="5">
                        <mat-icon *ngIf="node?.tree_type == 'connection' && node.hovered"
                        (click)="connectionPopup('edit', node)" style="cursor: pointer;margin-left: -6px;" color="accent"
                        matTooltip="Edit Connection">edit</mat-icon>
                      </div>
                      <div fxLayout="column" fxFlex="5">

                         <mat-icon *ngIf="node?.tree_type == 'connection' && node.hovered && hasDeleteAccess()"
                         (click)="deleteConnection(node)" style="cursor: pointer;" color="warn"
                        matTooltip="Delete Connection">delete</mat-icon>
                      </div>
                     </div>
                  <mat-spinner diameter="20" *ngIf="node.spin"></mat-spinner>
                  </mat-list-item>
                </mat-tree-node>
            </mat-tree>

            </mat-list>
        </div>
        <div fxLayout="column" fxFlex="5"></div>
      </div>
</div>

<mat-paginator style="width: 100%" [pageIndex]="pageIndex" [pageSize]="pageLimit" (page)="pageChanged($event)"
[length]="connectionService.connectionLength || 1000" [hidePageSize]="true"   #connectionListPaginator></mat-paginator>

<div >
  <div fxLayout="column" fxFlex="5"></div>
  <button mat-stroked-button color="primary" (click)="connectionPopup('create')">
      <mat-icon color="primary">add</mat-icon>  New Connection</button>
</div>
