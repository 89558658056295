export const environment = {
  env: "staging",
  production: false,
  build_number: '3040',
  version: `${require('../../package.json').version}-stage`,
  SERVER_BASE_URL: "https://us-central1-jcp-workspace-stage.cloudfunctions.net/server/api",
  BOX_URL:"https://us-central1-jcp-box-stage.cloudfunctions.net/server/api/box",
  DB_BOX_URL:"https://us-central1-jcp-box-stage.cloudfunctions.net/server/api/box",
  DB_BOX_TOKEN: "U2FsdGVkX1+t39pbPeZoaOX+PVDT9HMu+byHQZieOz9BdPFolVtNDhlZNeEJJVcDiFsCKHLsdzo/xh+J8igbaPV03Ps9i9S/ZQWXEUb0zs/KHAYLWSbgI6FPlQUt/e6VxlKXWy0Nvoz8GOE+fUREVcu+DBpvXHzkx8M/A0gCmVQAlH/v2KGR8eVu2ALel+vSo+RCx2joCC7owI5mk8nESw==",
  GOOGLE_CLIENT: "268462099178-ijqb8qmu5ek8pbkdhccqi92r1c3p7ggc.apps.googleusercontent.com",
  GOOGLE_SECRET: "4r4aXbZ9AoyKYQ8vbrC1mD-a",
  LOGO_BASE_URL: "https://storage.googleapis.com/jivrus-web-images/products/appiworks/",
  FORM_BANNER_BASE_URL: "https://storage.googleapis.com/jivrus-web-images/jcp/form/banner/",
  STARCH_LOGO_BASE_URL: "https://storage.googleapis.com/jivrus-web-images/jcp/starch/logo/",
  ASSETS_BASE_URL: "https://storage.googleapis.com/jivrus-web-images/products/appiworks/bloom/common-assets/",
  IMAGE_PLACEHOLDER: {
    PLACEHOLDER_GENERAL: "image-placeholder.jpg",
    PLACEHOLDER_NO_IMAGE: "no-image-placeholder.jpg"
  },
  MIXPANEL_KEY: "5e7358e50b9cdecfccaf796be825db12",
  GCS_BUCKET: "appiworks-stage.appspot.com",
  GCS_BASE_URL: "https://storage.googleapis.com",
  GCS_CONNECTION_ID: "646b14e834bb2e18635dfcb0",
  BLOOM_LOGO_BASE_URL: "https://storage.googleapis.com/jivrus-web-images/jcp/bloom/avatar/",
  HOTJAR_ID: "",
  GTM_KEY: "GTM-MJKRWZW",
  SES_CONNECTION_ID: "646b18452588d42e9d5428b7",
  BASE_URL: "https://stage.cd.appiworks.com",
  BASE_URL_ZOHO_BOOKS: "http://extension-books.zohosandbox.com/api/v3",
  BASE_URL_ZOHO_BILLINGS: "https://billing.zoho.com/api/v3",
  "SMS_BASE_URLS": [
    "https://us-central1-sms-stage.cloudfunctions.net/server/api/",
    "https://sms-stage.appspot.com/api/"
  ],
  "SMS_PORTAL_URL": "https://sms-stage.appspot.com/",
  SMS_AUTH_KEY : "fddd0981-68c2-4e8b-bf9f-87026cdadfb4",
  INTERNAL_BLOOM: {
    ARTICLE: "bloom-5d754ef3",
    INTEGRATIONS: "lousy-snail-404d185b",
    LEGAL: "",
    BLOOM_TEMPLATES: "",
    FLOW_TEMPLATES: "flow-templates",
    DEMO_BLOOMS: "",
    FORM_TEMPLATES: "form-templates-71c49ad0"
  },
  BOUTIQUE_WORKSPACE: "65c1fdab0634b1e56468a306"


};
